import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { debounce } from "lodash";
import { getRestaurantDetails, saveRestaurantDetails, getPlatformsList, getCitiesList } from "../../api/auth";
import TopBar from '../../components/TopBar';
import Footer from "../../components/Footer";
import LoadingCard from '../../components/LoadingCard';
import CountryCodeSelect from "../../components/CountryCodeSelect";
import UpInput from "../../components/Common/UpInput";
import UpCheckbox from "../../components/Common/UpCheckbox";
import UpSelect from "../../components/Common/UpSelect";
import UpMultiSelect from "../../components/Common/UpMultiSelect";
import UpRadio from "../../components/Common/UpRadio";
import UpLoadingBar from "../../components/Common/UpLoadingBar";
import UpLoadingCover from "../../components/Common/UpLoadingCover";
import UpToast from "../../components/Common/UpToast";
import CardHeader from "../../components/CardHeader";
import BusinessType from "../../components/BusinessType";
import FeaturedApps from "../../assets/icons/FeaturedApps";
import OtherApps from "../../assets/icons/OtherApps";
import { checkErrorTypeString, isValidPhoneNumberOrEmail } from "../../helpers/utils";
import { RESTAURANT_TYPES, POS_LIST } from "../../constants/client-config";

function OnboardingForm() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [radio, setRadio] = useState(false);
  const [restaurantDetails, setRestaurantDetails] = useState({});
  const [platformsList, setPlatformsList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [citySearchQuery, setCitySearchQuery] = useState('');
  const [errors, setErrors] = useState({});
  const [showFooter, setShowFooter] = useState(true);
  const [step, setStep] = useState(1);

  const { isLoading } = useQuery({
    queryKey: ['getRestaurantDetails'],
    queryFn: () => getRestaurantDetails(searchParams.get('token')),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: transformApiResp,
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      if (errorMessage === "Biz activation already done") {
        navigate('/onboarding/complete');
      } else {
        UpToast.error(errorMessage);
        navigate('/');
      }
    },
  });

  const { isLoading: platformsLoading } = useQuery({
    queryKey: ['getPlatformsList'],
    queryFn: () => getPlatformsList(searchParams.get('token')),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: transformPlatformsApiResp,
  });

  const { isLoading: citiesLoading, refetch, data } = useQuery({
    queryKey: ['getCitiesList', citySearchQuery],
    queryFn: () => getCitiesList(searchParams.get('token'), {
      country: restaurantDetails?.country,
      city: citySearchQuery
    }),
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    retry: false,
    onSuccess: transformCitiesApiResp,
  });

  const { isLoading: formLoading, mutate: submitRestaurantDetails } = useMutation({
    mutationFn: ({ params }) => saveRestaurantDetails(searchParams.get('token'), params),
    onSuccess: onSaveDetailsSuccess,
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      UpToast.error(errorMessage);
    },
  });

  useEffect(() => {
    if (restaurantDetails?.country) {
      refetch();
    }
  }, [restaurantDetails?.country]);

  function transformApiResp(resp) {
    setRestaurantDetails({
      ...resp,
      is_multi_brand: true,
      is_menu_over_catalogue_enabled: true,
      no_of_outlets: 0,
      restaurant_types: [],
      point_of_sale: resp.point_of_sale ? {label: resp.point_of_sale, value: resp.point_of_sale} : null,
      platforms: resp.platforms?.map((plf) => ({ display_name: plf, name: plf })),
      addresses: resp.addresses?.map((a) => ({
        ...a,
        city: a.city ? {display_name: a.city, name: a.city} : a.city,
        state: a.state ? {display_name: a.state, name: a.state} : a.state
      }))
    });
  }

  function transformPlatformsApiResp(resp) {
    setPlatformsList(resp?.platforms);
  }

  const handleCitiesList = useCallback(
		debounce((search = '') => {
			setCitySearchQuery(search);
		}, 300),
		[]
	);

  function transformCitiesApiResp(resp) {
    if (resp?.data?.length) {
      setCitiesList(resp?.data?.map((city) => ({
        ...city,
        display_name: city?.name
      })));
    }
  }

  function onSaveDetailsSuccess(resp) {
    if (step === 1) {
      setStep(2);
      setIsFormTouched(false);
      return;
    }
    setShowFooter(false);
    setTimeout(() => {
      navigate('/onboarding/complete');
    }, 100);
  }

  function updateForm(field, updated, index, subField) {
    let updatedRestaurantDetails = { ...restaurantDetails };
    if (index !== undefined) {
      updatedRestaurantDetails[field] = [...(updatedRestaurantDetails[field] ?? [{}])].map((obj, i) => {
        let updatedObj = obj;
        if (i === index) {
          updatedObj = updated;
          setErrors({
            ...errors,
            [field]: {
              ...(errors?.[field] ?? {}),
              [subField]: ""
            }
          });
        }
        return updatedObj;
      });
    } else {
      updatedRestaurantDetails[field] = updated;
      setErrors({
        ...errors,
        [field]: ""
      });
    }
    setRestaurantDetails(updatedRestaurantDetails);
    setIsFormTouched(true);
  }

  function handleSubmit(activate = false) {
    const validations = {};
    let params = {...JSON.parse(JSON.stringify(restaurantDetails))};
    let formError = false;
    const errorMessage = "This field cannot be empty";

    params.restaurant_types = [...(params.restaurant_types ?? [])].map((obj) => obj.value);
    params.platforms = [...(params.platforms ?? [])].map((obj) => obj.name);
    params.addresses = params.addresses.map((a) => {
      a.city = a?.city?.name || null
      a.state = a?.state?.name || null
      return a;
    });
    params.point_of_sale = params.point_of_sale?.value || null;

    if (activate) {
      params.activate_biz = true;
    }

    if (!params?.name) {
      formError = true;
      validations.name = errorMessage;
    }

    if (params?.no_of_outlets === "") {
      formError = true;
      validations.no_of_outlets = errorMessage;
    }

    if (!params?.addresses[0]?.city) {
      formError = true;
      validations.addresses = {
        ...(validations?.addresses ?? {}),
        city: errorMessage,
      };
    }

    if (!params?.addresses[0]?.line1) {
      formError = true;
      validations.addresses = {
        ...(validations?.addresses ?? {}),
        line1: errorMessage,
      };
    }

    if (!params?.addresses[0]?.postal_code) {
      formError = true;
      validations.addresses = {
        ...(validations?.addresses ?? {}),
        postal_code: errorMessage,
      };
    }

    if (params?.primary_contact_numbers?.[0] && !isValidPhoneNumberOrEmail(params?.primary_contact_numbers?.[0])) {
      formError = true;
      validations.primary_contact_numbers = "Enter valid mobile number";
    }

    if (!radio && params?.platforms?.length === 0) {
      formError = true;
      validations.platforms = errorMessage;
    }

    if (step === 1 && formError) {
      setErrors(validations);
      return;
    }

    submitRestaurantDetails({ params });
  }

  async function handleContinue() {
    if (step === 1) {
      handleSubmit(false);
      return;
    }

    if (step === 2) {
      handleSubmit(true);
    }
  }

  function handleBack() {
    setStep(1);
  }

  function renderTopBarContentRight() {
    return (
      <CountryCodeSelect
        value={restaurantDetails?.country}
        setCountryCode={() => {}}
        countryCode={false}
        countryName={true}
        isDisabled
      />
    );
  }

  return (
    <div className="bg-up-clr-lightest-grey w-full min-h-screen flex flex-col">
      <TopBar
        showLogout={false}
        isSticky={true}
        showContentRight={!isLoading}
        renderContentRight={renderTopBarContentRight()}
      />
      <div className="flex items-center justify-center w-full flex-grow pt-8 pb-10 px-4">
        {isLoading ? (
          <LoadingCard text="Loading restaurant details..." />
        ) : (
          <div className="mb-8">
            <UpLoadingBar loading={isLoading || formLoading} />
            {
              step === 1 &&
              <div
                className="onboarding-form-wrapper light-view-wrapper relative"
                style={{ paddingLeft: 0, paddingRight: 0 }}>
                <UpLoadingCover loading={isLoading || formLoading} />
                <div>
                  <div className="px-4 md:px-6 mb-10">
                    <CardHeader
                      title="Confirm Restaurant Details"
                      subtitle="Verify basic details for your restaurant"
                    />
                  </div>
                  <div className="px-4 md:px-6">
                    <form>
                      <UpInput
                        type="text"
                        name="name"
                        wrapperClass="mb-6"
                        label="Company Name"
                        placeholder="Company Name"
                        requiredLabel={true}
                        value={restaurantDetails?.name || ""}
                        onChange={updated => updateForm("name", updated)}
                        errorContent={errors?.name}
                      />
                      {/* <BusinessType
                        wrapperClass="mb-6"
                        value={restaurantDetails?.is_multi_brand}
                        onChange={updated => updateForm("is_multi_brand", updated)}
                      /> */}
                      <UpInput
                        type="number"
                        name="no_of_outlets"
                        wrapperClass="mb-10"
                        label="Number of outlets"
                        placeholder="Number of outlets"
                        requiredLabel={true}
                        value={restaurantDetails?.no_of_outlets}
                        onChange={updated => updateForm("no_of_outlets", updated >= 0 ? updated : 0)}
                        errorContent={errors?.no_of_outlets}
                      />
                      <p className="text-base sm:text-lg font-semibold text-up-clr-black mb-4">
                        What type of restaurant business do you run?
                      </p>
                      <UpMultiSelect
                        label="Type of restaurant"
                        wrapperClass="mb-10"
                        options={RESTAURANT_TYPES}
                        name="restaurant_types"
                        value={restaurantDetails?.restaurant_types || []}
                        onValueChange={updated => updateForm("restaurant_types", updated)}
                      />
                      <p className="text-base sm:text-lg font-semibold text-up-clr-black mb-4">
                        Where do you operate from?
                      </p>
                      <div className="block sm:flex gap-4 mb-6">
                        <UpSelect
                          label="City"
                          name="City"
                          requiredLabel={true}
                          wrapperClass="flex-1"
                          isLoading={citiesLoading}
                          options={citiesList}
                          onValueChange={updated => updateForm("addresses", {
                            ...(restaurantDetails?.addresses?.[0] ?? {}),
                            city: updated,
                            state: updated ? {display_name: updated?.state_name, name: updated?.state_name} : null
                          }, 0, "city")}
                          value={restaurantDetails?.addresses?.[0]?.city || null}
                          labelKey="display_name"
                          valueKey="name"
                          isAsync
                          handleSearch={handleCitiesList}
                          placeholder="Type to search"
                          errorContent={errors?.addresses?.city}
                        />
                        <UpSelect
                          label="State"
                          name="State"
                          wrapperClass="flex-1"
                          options={restaurantDetails?.addresses?.[0]?.state ? [restaurantDetails?.addresses?.[0]?.state] : []}
                          onValueChange={updated => updateForm("addresses", {
                            ...(restaurantDetails?.addresses?.[0] ?? {}),
                            state: updated
                          }, 0, "state")}
                          isClearable={false}
                          value={restaurantDetails?.addresses?.[0]?.state || null}
                          labelKey="display_name"
                          valueKey="name"
                          placeholder="State"
                          errorContent={errors?.addresses?.state}
                          isDisabled
                        />
                      </div>
                      <UpInput
                        autoFocus={errors?.addresses?.line1 ? true : false}
                        type="text"
                        name="addresses"
                        label="Billing Address"
                        requiredLabel={true}
                        placeholder="Line 1"
                        wrapperClass="mb-6"
                        value={restaurantDetails?.addresses?.[0]?.line1 || ""}
                        onChange={updated => updateForm("addresses", {
                          ...(restaurantDetails?.addresses?.[0] ?? {}),
                          line1: updated
                        }, 0, "line1")}
                        errorContent={errors?.addresses?.line1}
                      />
                      <UpInput
                        type="text"
                        name="addresses"
                        placeholder="Line 2"
                        wrapperClass="mb-6"
                        value={restaurantDetails?.addresses?.[0]?.line2 || ""}
                        onChange={updated => updateForm("addresses", {
                          ...(restaurantDetails?.addresses?.[0] ?? {}),
                          line2: updated
                        }, 0, "line2")}
                        errorContent={errors?.addresses?.line2}
                      />
                      <UpInput
                        autoFocus={errors?.addresses?.postal_code ? true : false}
                        type="text"
                        name="addresses"
                        label="Pincode"
                        requiredLabel={true}
                        placeholder="Pincode"
                        wrapperClass="mb-6"
                        value={restaurantDetails?.addresses?.[0]?.postal_code || ""}
                        onChange={updated => updateForm("addresses", {
                          ...(restaurantDetails?.addresses?.[0] ?? {}),
                          postal_code: updated
                        }, 0, "postal_code")}
                        errorContent={errors?.addresses?.postal_code}
                      />
                      <UpInput
                        autoFocus={errors?.primary_contact_numbers ? true : false}
                        type="text"
                        name="primary_contact_numbers"
                        wrapperClass="mb-10"
                        label="Mobile Number"
                        placeholder="XXX-XXX-XXXX"
                        value={restaurantDetails?.primary_contact_numbers?.[0] || ""}
                        onChange={updated => updateForm("primary_contact_numbers", [updated])}
                        errorContent={errors?.primary_contact_numbers}
                      />
                      <p className="text-base sm:text-lg font-semibold text-up-clr-black mb-4">
                        Are your menus and locations online?
                      </p>
                      <UpMultiSelect
                        label="What Online Ordering Platforms do you operate on?"
                        requiredLabel={true}
                        options={platformsList}
                        isLoading={platformsLoading}
                        name="platforms"
                        value={restaurantDetails?.platforms || []}
                        onValueChange={updated => updateForm("platforms", updated)}
                        labelKey="display_name"
                        valueKey="name"
                        isDisabled={radio}
                        wrapperClass="mb-6"
                        errorContent={errors?.platforms}
                      />
                      <UpSelect
                        label="What Point of Sale do you use?"
                        options={POS_LIST}
                        placeholder="Select"
                        wrapperClass="mb-6"
                        value={restaurantDetails?.point_of_sale || null}
                        onValueChange={updated => updateForm("point_of_sale", updated)}
                        isDisabled={radio}
                      />
                      <UpRadio
                        label="I do not use any online services for my restaurant "
                        value={radio}
                        wrapperClass="mt-2 mb-10"
                        onChange={(updated) => {
                          setRadio(updated);
                          if (updated) {
                            setRestaurantDetails({
                              ...restaurantDetails,
                              "point_of_sale": null,
                              "platforms": [],
                            });
                            setErrors({
                              ...errors,
                              platforms: ""
                            });
                          }
                        }}
                      />
                      <p className="text-base sm:text-lg font-semibold text-up-clr-black mb-4">
                        Primary Admin Details
                      </p>
                      <div className="block sm:flex gap-4">
                        <div className="read-only-text flex-1 mb-4 sm:mb-0">
                          <label>Name</label>
                          <div className="value">{restaurantDetails?.primary_contact || '--'}</div>
                        </div>
                        <div className="read-only-text flex-1 mb-4 sm:mb-0">
                          <label>Email</label>
                          <div className="value">{restaurantDetails?.primary_contact_emails?.[0] || '--'}</div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            }
            {
              step === 2 &&
              <div
                className="onboarding-form-wrapper light-view-wrapper relative"
                style={{ paddingLeft: 0, paddingRight: 0 }}>
                <UpLoadingCover loading={isLoading || formLoading} />
                <div>
                  <div className="px-4 md:px-6 mb-10">
                    <CardHeader
                      title="Preview UrbanPiper product suite as per your restaurant"
                      // subtitle="Select apps you want to proceed with"
                    />
                  </div>
                  <div className="px-4 md:px-6">
                    <form>
                      <div className="block sm:flex gap-4 mb-4">
                        <div className="block sm:flex flex-1 items-center gap-4">
                          <FeaturedApps />
                          <div className="text-base sm:text-lg font-semibold text-up-clr-black">
                            All Featured Apps
                          </div>
                        </div>
                        <div className="block sm:flex flex-1 items-center gap-4">
                          <OtherApps />
                          <div className="text-base sm:text-lg font-semibold text-up-clr-black">
                            Other Apps
                          </div>
                        </div>
                      </div>
                      <div className="block sm:flex gap-4 mt-2">
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1">
                          <UpCheckbox
                            label="Online Order Management"
                            value={restaurantDetails?.apps?.includes('hub') || false}
                            wrapperClass="mt-2"
                            isDisabled
                          />
                        </div>
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1">
                          <UpCheckbox
                            label="Contactless Ordering"
                            value={
                              restaurantDetails?.apps?.includes('qr-dinein') ||
                              restaurantDetails?.apps?.includes('qr-qsr') ||
                              false
                            }
                            wrapperClass="mt-2"
                            isDisabled
                          />
                        </div>
                      </div>
                      <div className="block sm:flex gap-4">
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1">
                          <UpCheckbox
                            label="Direct Orders (Web & App)"
                            value={restaurantDetails?.apps?.includes('meraki') || false}
                            wrapperClass="mt-2"
                            isDisabled
                          />
                        </div>
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1">
                          <UpCheckbox
                            label="Logistics Management"
                            value={restaurantDetails?.apps?.includes('logistics') || false}
                            wrapperClass="mt-2"
                            isDisabled
                          />
                        </div>
                      </div>
                      <div className="block sm:flex gap-4">
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1">
                          <UpCheckbox
                            label="POS"
                            value={restaurantDetails?.apps?.includes('pos') || false}
                            wrapperClass="mt-2"
                            isDisabled
                          />
                        </div>
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1">
                          <UpCheckbox
                            label="Inventory"
                            value={restaurantDetails?.apps?.includes('inventory') || false}
                            wrapperClass="mt-2"
                            isDisabled
                          />
                        </div>
                      </div>
                      <div className="block sm:flex gap-4">
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1">
                          <UpCheckbox
                            label="Periscope"
                            value={restaurantDetails?.apps?.includes('periscope') || false}
                            wrapperClass="mt-2"
                            isDisabled
                          />
                        </div>
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1">
                          <UpCheckbox
                            label="Loyalty"
                            value={restaurantDetails?.apps?.includes('loyalty') || false}
                            wrapperClass="mt-2"
                            isDisabled
                          />
                        </div>
                      </div>
                      <div className="block sm:flex gap-4">
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1"></div>
                        <div className="block sm:flex flex-1 gap-4 mb-4 px-1">
                          <UpCheckbox
                            label="Captain App (POS)"
                            value={restaurantDetails?.apps?.includes('captain-app') || false}
                            wrapperClass="mt-2"
                            isDisabled
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            }
          </div>
        )}
      </div>
      {!isLoading && showFooter && (
        <Footer
          showBtnLeft={step === 2}
          handleBtnRight={handleContinue}
          handleBtnLeft={handleBack}
          rightBtnText={step === 2 ? "Confirm and Activate" : "Continue"}
          rightBtnLoading={formLoading}
        />
      )}
    </div>
  );
}
export default OnboardingForm;
