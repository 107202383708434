import React, { useEffect } from 'react';
import UpBlackImg from '../../assets/images/up-black.svg';
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';
import UpButton from "../../components/Common/UpButton";
import { getBaseUrlTempAccess } from "../../helpers/environment.js";
import './style.scss';

function WelcomeScreen() {

  function handleContinueToSetup() {
    setTimeout(() => {
      window.location.href = getBaseUrlTempAccess();
    }, 500);
  }

  return (
    <div className="welcome-screen-wrapper">
      <div className="circle-top-right"></div>
      <div className="welcome-text text-white">Welcome to UrbanPiper</div>
      <img
        src={UpBlackImg}
        alt="urbanpiper"
        className="up"
      />
      <UpButton
        variant="link"
        wrapperClass="w-max ml-auto light"
        onClick={handleContinueToSetup}>
          <div className="flex items-center gap-2 text-xl leading-3">
            Continue to Setup 
            <ArrowRightIcon />
          </div>
      </UpButton>
      <div className="circle-bottom-left"></div>
    </div>
  );
}
export default WelcomeScreen;
