import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import EyeOpenIcon from '../../../assets/icons/EyeOpenIcon';
import EyeCloseIcon from '../../../assets/icons/EyeCloseIcon';
import './style.scss';

function UpInput({
  name,
  value,
  label,
  requiredLabel = false,
  secondaryLabel = "",
  loading,
  autoFocus,
  helpContent,
  placeholder,
  errorContent,
  type = 'text',
  extraInput = null,
  wrapperClass = '',
  extraAction = null,
  showExtraInput = false,
  onBlur,
  onChange,
}) {
  const inputRef = useRef(null);
  const isPasswordType = type === 'password';
  const [inputType, setInputType] = useState(type);

  useEffect(() => {
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  function onToggleInputType() {
    if (inputType === 'password') {
      setInputType('text');
      return;
    }

    setInputType('password');
  }

  function onInputChange(e) {
    if (!onChange || loading) {
      return;
    }

    onChange(e.target.value);
  }

  function onInputBlur() {
    if (!onBlur) {
      return;
    }

    onBlur();
  }

  function getHelpErrorContent() {
    if (errorContent) {
      return <div className="error-content">{errorContent}</div>;
    }

    if (helpContent) {
      return <div className="help-content">{helpContent}</div>;
    }

    return null;
  }

  return (
    <div
      className={classNames('up-input', wrapperClass, {
        'up-error-input': errorContent,
      })}>
      {label && (
        <label className={requiredLabel ? 'required-label' : ''} htmlFor={name}>
          {label}
          {secondaryLabel && <span className="secondary-label">{secondaryLabel}</span>}
        </label>
      )}
      <div className="input-wrapper">
        {extraInput && (
          <div
            className={classNames('extra-input', {
              'show-extra-input': showExtraInput,
            })}>
            {extraInput}
          </div>
        )}
        <input
          name={name}
          value={value}
          ref={inputRef}
          type={inputType}
          onBlur={onInputBlur}
          onChange={onInputChange}
          onWheel={(e) => e.target.blur()}
          placeholder={placeholder}
        />
        {isPasswordType && (
          <div onClick={onToggleInputType} className="password-icon">
            {inputType === 'password' ? <EyeOpenIcon /> : <EyeCloseIcon />}
          </div>
        )}
      </div>
      {getHelpErrorContent()}
      {extraAction && <div className="mt-2">{extraAction}</div>}
    </div>
  );
}

export default UpInput;
