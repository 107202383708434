export const RESTAURANT_TYPES = [
  {label: "Casual dining", value: "Casual dining"},
  {label: "Fine dining", value: "Fine dining"},
  {label: "QSR", value: "QSR"},
  {label: "Cafe", value: "Cafe"},
  {label: "Pub", value: "Pub"},
  {label: "Bar", value: "Bar"},
  {label: "Cloud kitchen", value: "Cloud kitchen"},
];

export const POS_LIST = [
  {label: "3D SYS", value: "3D SYS"},
  {label: "3sPOS", value: "3sPOS"},
  {label: "ALLPOS", value: "ALLPOS"},
  {label: "Aatithya", value: "Aatithya"},
  {label: "Access", value: "Access"},
  {label: "Al Amar", value: "Al Amar"},
  {label: "BIMPOS", value: "BIMPOS"},
  {label: "Billberry", value: "Billberry"},
  {label: "Biz1Book", value: "Biz1Book"},
  {label: "Blue Lotus", value: "Blue Lotus"},
  {label: "Bridge", value: "Bridge"},
  {label: "Buvvas", value: "Buvvas"},
  {label: "Cibo", value: "Cibo"},
  {label: "Ciferon", value: "Ciferon"},
  {label: "Cloud Me", value: "Cloud Me"},
  {label: "Clover", value: "Clover"},
  {label: "Cypheron", value: "Cypheron"},
  {label: "DIL POS", value: "DIL POS"},
  {label: "Devourin", value: "Devourin"},
  {label: "Digipos", value: "Digipos"},
  {label: "Digirestro", value: "Digirestro"},
  {label: "Digital Arena (Oracle Netsuite)", value: "Digital Arena (Oracle Netsuite)"},
  {label: "E-retail", value: "E-retail"},
  {label: "EBT", value: "EBT"},
  {label: "EDM ", value: "EDM "},
  {label: "EPOS Hybrid/Grafterr", value: "EPOS Hybrid/Grafterr"},
  {label: "Easy Resto", value: "Easy Resto"},
  {label: "Easy Resto ", value: "Easy Resto "},
  {label: "Edlar", value: "Edlar"},
  {label: "Enstore", value: "Enstore"},
  {label: "Epos Now", value: "Epos Now"},
  {label: "Flip Office", value: "Flip Office"},
  {label: "Flipdish", value: "Flipdish"},
  {label: "Foaps", value: "Foaps"},
  {label: "Food kart", value: "Food kart"},
  {label: "Foodhub", value: "Foodhub"},
  {label: "Foodics", value: "Foodics"},
  {label: "Foodkart", value: "Foodkart"},
  {label: "Geidea ", value: "Geidea "},
  {label: "GoFrugal", value: "GoFrugal"},
  {label: "Growdash", value: "Growdash"},
  {label: "HotSoft", value: "HotSoft"},
  {label: "Hungerbox", value: "Hungerbox"},
  {label: "I-Dine", value: "I-Dine"},
  {label: "IIKO/ Syrve", value: "IIKO/ Syrve"},
  {label: "IIKO/ Syrve v2", value: "IIKO/ Syrve v2"},
  {label: "Idine", value: "Idine"},
  {label: "JPOS", value: "JPOS"},
  {label: "LS Retail ", value: "LS Retail "},
  {label: "Lightspeed", value: "Lightspeed"},
  {label: "Loyverse", value: "Loyverse"},
  {label: "Lucid", value: "Lucid"},
  {label: "Lulu ", value: "Lulu "},
  {label: "Menew", value: "Menew"},
  {label: "Mentor ", value: "Mentor "},
  {label: "NCR Aloha", value: "NCR Aloha"},
  {label: "NOQ", value: "NOQ"},
  {label: "NcrBspPOS", value: "NcrBspPOS"},
  {label: "Omega", value: "Omega"},
  {label: "Oracle MICROS Symphony Gen 1", value: "Oracle MICROS Symphony Gen 1"},
  {label: "Oracle MICROS Symphony Gen 2", value: "Oracle MICROS Symphony Gen 2"},
  {label: "Paytm Billing", value: "Paytm Billing"},
  {label: "Phylo POS", value: "Phylo POS"},
  {label: "Polaris", value: "Polaris"},
  {label: "Posbytz", value: "Posbytz"},
  {label: "Poserve", value: "Poserve"},
  {label: "Posify", value: "Posify"},
  {label: "Posist", value: "Posist"},
  {label: "Prologic", value: "Prologic"},
  {label: "Q4 Retail", value: "Q4 Retail"},
  {label: "QPOS", value: "QPOS"},
  {label: "QRKey Pos", value: "QRKey Pos"},
  {label: "QuantumPOS", value: "QuantumPOS"},
  {label: "Queuebuster", value: "Queuebuster"},
  {label: "Rancelab", value: "Rancelab"},
  {label: "Revel", value: "Revel"},
  {label: "Rise POS", value: "Rise POS"},
  {label: "RoyalPOS", value: "RoyalPOS"},
  {label: "SVIKA", value: "SVIKA"},
  {label: "Samba", value: "Samba"},
  {label: "Sapaad", value: "Sapaad"},
  {label: "Semnox-N", value: "Semnox-N"},
  {label: "Shawman", value: "Shawman"},
  {label: "Shift4", value: "Shift4"},
  {label: "Sigmoss", value: "Sigmoss"},
  {label: "Slick", value: "Slick"},
  {label: "Smart", value: "Smart"},
  {label: "Smartserv", value: "Smartserv"},
  {label: "SpeQue", value: "SpeQue"},
  {label: "Square", value: "Square"},
  {label: "Squirrel", value: "Squirrel"},
  {label: "SumUp", value: "SumUp"},
  {label: "SummerPOS (Toaspie)", value: "SummerPOS (Toaspie)"},
  {label: "TechMainStay", value: "TechMainStay"},
  {label: "Technotrust ", value: "Technotrust "},
  {label: "Tevalis", value: "Tevalis"},
  {label: "TouchBistro", value: "TouchBistro"},
  {label: "Urbandart", value: "Urbandart"},
  {label: "Vimptech POS", value: "Vimptech POS"},
  {label: "Yotto Labs", value: "Yotto Labs"}
]
