import 'abortcontroller-polyfill'
import ResizeObserver from 'resize-observer-polyfill';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { PostHogProvider} from 'posthog-js/react'
import {
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
} from 'react-router-dom';
import App from './app';
import { WEB_VERSION } from './version';
import './style.scss';
import { getMixPanelToken, getPostHogKey, getPostHogHost } from './helpers/environment';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: 'https://87534e7e098743fca2518a20fcc71a94@o16291.ingest.us.sentry.io/4505549222117376',
    release: 'auth-service-fe@' + WEB_VERSION,
    environment: import.meta.env.ULE_BUILD_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    ignoreErrors: ['Non-Error promise rejection captured'],
  });
}

// if (import.meta.env.ULE_BUILD_ENV === 'production') {
//   mixpanel.init(getMixPanelToken());
// }

// initiate posthog
posthog.init(getPostHogKey(), {
  api_host: getPostHogHost(),
  person_profiles: "identified_only",
  autocapture: false,
  capture_pageview: false,
  capture_pageleave: false,
  enable_recording_console_log: true,
	advanced_disable_feature_flags_on_first_load: true
});

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
);
