import React from 'react';
import { useNavigate } from 'react-router-dom';
import UpButton from '../Common/UpButton';
import "./style.scss"

function Footer({ 
  showBtnLeft = false, 
  showBtnRight = true, 
  handleBtnRight, 
  handleBtnLeft,
  leftBtnText = 'Back',
  rightBtnText = 'Continue',
  rightBtnLoading = false,
}) {
  const navigate = useNavigate();

  return (
    <div className="bottom-footer bg-white py-5 px-4 md:px-5 flex justify-between items-center bottom-0 z-50 sticky">
      {showBtnLeft && (
        <UpButton
          type="button"
          wrapperClass="w-max mr-auto"
          onClick={handleBtnLeft}
          isSecondary
        >
          {leftBtnText}
        </UpButton>
      )}
      {showBtnRight && (
        <UpButton
          type="button"
          wrapperClass="w-max ml-auto"
          onClick={handleBtnRight}
          loading={rightBtnLoading}
        >
          {rightBtnText}
        </UpButton>
      )}
    </div>
  );
}

export default Footer;
