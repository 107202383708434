import PathStatus from '../constants/PathStatus';
import LocalStorageHelper from './localStorageHelpers';
import AtlasLogo from '../assets/images/atlas-logo.svg';
import PrimeLogo from '../assets/images/prime-logo.svg';
import UpToast from '../components/Common/UpToast';

/**
 * Ordermark countries list
 */
export const OMK_COUNTRIES = ['US', 'CA'];

/**
 * Save API response data and return a redirect route for auth service
 */
export function getRedirectRoute(response) {
  const pathStatusResponse = response?.path_status;
  const bizData = response?.data?.userbiz_data || [];
  const isAppLogin = LocalStorageHelper.getAppLogin();

  if (isAppLogin) {
    const updatedBizData = bizData
      ?.map(data => {
        const validBizs = data?.appsbiz?.filter(
          biz => biz?.app_name === isAppLogin,
        );

        if (validBizs?.length) {
          return {
            ...data,
            appsbiz: validBizs,
          };
        }

        return null;
      })
      .filter(b => !!b);
    LocalStorageHelper.setBizData(updatedBizData);
  } else {
    LocalStorageHelper.setBizData(bizData);
  }

  if (response?.access_token) {
    LocalStorageHelper.setAccessToken(response?.access_token);
  }

  if (pathStatusResponse === PathStatus.UPDATE_UNIQUE_DATA) {
    LocalStorageHelper.setDuplicateData(response?.data?.data_to_merge);
    return '/update-account';
  }

  if (pathStatusResponse === PathStatus.CREATE_NEW_PASSWORD) {
    return '/set-password';
  }

  if (pathStatusResponse === PathStatus.APP_BIZ_SELECTION) {
    return '/business';
  }

  if (pathStatusResponse === PathStatus.PENDING_INVITES) {
    const inviteData = transformInviteResponse(response?.data);
    LocalStorageHelper.setInviteData(inviteData);
    return '/invite';
  }

  if (pathStatusResponse === PathStatus.LOGIN) {
    if (response?.message) {
      UpToast.error(response?.message);
    }
    return '/logout';
  }

  return '/logout';
}

/**
 * Get all linked accounts for a user
 */
export function getLinkedAccounts() {
  const defaultStructure = {
    atlas: { platform: 'ATLAS', accounts: [] },
    prime: { platform: 'PRIME', accounts: [] },
  };
  const allBizData = LocalStorageHelper.getBizData();

  const linkedAccounts = allBizData?.reduce((acc, current) => {
    (current?.appsbiz || []).forEach(biz => {
      acc[biz?.app_name].accounts.push({
        ...biz,
        biz_logo: current?.biz_logo,
        biz_name: current?.biz_name,
      });
    });
    return acc;
  }, defaultStructure);

  return Object.values(linkedAccounts || {});
}

/**
 * Get all available platforms for a business
 */
export function getAvailablePlatforms(bizId) {
  const defaultStructure = {
    atlas: {
      id: 'atlas',
      name: 'Atlas',
      logo: AtlasLogo,
      desc: 'Manage your locations, platforms, and online orders, and get actionable business insights.',
      bizId: null,
      multiBiz: false,
    },
    prime: {
      id: 'prime',
      name: 'Prime',
      logo: PrimeLogo,
      desc: 'Manage your in-store and online operations from a single system.',
      bizId: null,
      multiBiz: false,
    },
  };
  const allBizData = LocalStorageHelper.getBizData();
  const findBiz = allBizData?.find(d => d?.biz_id === Number(bizId));

  if (findBiz) {
    findBiz?.appsbiz?.forEach(biz => {
      if (defaultStructure[biz?.app_name].bizId) {
        defaultStructure[biz?.app_name].multiBiz = true;
      } else {
        defaultStructure[biz?.app_name].bizId = biz?.app_biz_id;
      }
    });
  }

  return {
    selectedBiz: findBiz?.biz_name,
    platforms: Object.values(defaultStructure || {}),
  };
}

/**
 * Get all linked brands for a business
 */
export function getLinkedBrands(bizId, platform) {
  const brands = [];
  const allBizData = LocalStorageHelper.getBizData();
  const findBiz = allBizData?.find(d => d?.biz_id === Number(bizId));

  if (findBiz) {
    findBiz?.appsbiz?.forEach(biz => {
      if (biz?.app_name === platform) {
        brands.push(biz);
      }
    });
  }

  return brands;
}

/**
 * Redirect user to selected platform
 */
export function redirectUserToPlatform(response) {
  const isAppLogin = LocalStorageHelper.getAppLogin();
  const { isMobile } = LocalStorageHelper.getUserDetails();
  const redirectUrl = response?.data?.redirect_data?.redirect_url;
  const clientToken = response?.data?.redirect_data?.client_token;

  if (isAppLogin && window.ReactNativeWebView) {
    LocalStorageHelper.clearUserDataOnLogout(false);
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        token: clientToken,
        authType: isMobile ? 'phone' : 'email',
      }),
    );
    return;
  }

  if (redirectUrl && clientToken) {
    window.open(
      `${redirectUrl}?access_token=${clientToken}&auth=${
        isMobile ? 'phone' : 'email'
      }`,
      '_self',
    );
  }
}

/**
 * Transform invite API response
 */
export function transformInviteResponse(data) {
  let bizCount = 0;
  const invitedBy = [];
  const defaultStructure = {
    atlas: { platform: 'Atlas', accounts: [] },
    prime: { platform: 'Prime', accounts: [] },
  };

  data?.pending_invites?.forEach(invite => {
    const inviteeName = invite?.invited_by?.name;
    if (inviteeName && !invitedBy?.includes(inviteeName)) {
      invitedBy.push(inviteeName);
    }

    invite?.invited_apps?.forEach(app => {
      if (defaultStructure[app.app_name]) {
        bizCount += 1;
        defaultStructure[app.app_name].accounts.push({
          ...app,
          roles: app?.roles?.map(r => r.name),
        });
      }
    });
  });

  return {
    details: {
      bizCount,
      email: data?.invited_email,
      phone: data?.invited_phone,
      isdCode: data?.invited_isd_code,
      invitedBy: invitedBy?.join(', '),
      invitedThrough: data?.invited_through,
    },
    invites: Object.values(defaultStructure || {}),
  };
}
